import NavFactory from "@/client/extensions/helpers/navFactory.js";
export default async ({ asyncOps, store }) => {
  // older code - we did not have navFactory then
  let adminNavItemFactoryFunction;
  let navItems;
  try {
    adminNavItemFactoryFunction =
      await require("@/client/assets/static/core/nav/adminSideNavFactory.js");
    navItems = await adminNavItemFactoryFunction.default({ asyncOps, store });
  } catch (e) {
    return {};
  }

  let nav = new NavFactory(navItems);

  let contentTitle = {
    action: {
      type: "title",
    },
    label: "s.admin.sideNav.contentTitle",
  };
  let presentation = {
    icon: "info",
    label: "s.admin.sideNav.presentation",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-entity-entityType",
          params: { entityType: "presentation" },
        },
      },
    },
  };
  let team = {
    icon: "users",
    label: "s.admin.sideNav.team",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-entity-entityType",
          params: { entityType: "team" },
        },
      },
    },
  };

  let project = {
    icon: "copy-content-reverse",
    label: "s.admin.sideNav.project",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-entity-entityType",
          params: { entityType: "project" },
        },
      },
    },
  };
  nav.removeItems(["ecommerceTitle", "order", "product", "vendor", "brand"]);
  nav.addItemsAfter("dashboard", {
    contentTitle,
    presentation,
    team,
    project,
  });

  return nav.getItems();
};
