module.exports = {
  name: "Saffron Technologies",
  iconPaths: {
    favicon32: "saffronSite/images/favicons/favicon-32x32.png",
    favicon16: "saffronSite/images/favicons/favicon-16x16.png",
    appleTouchIcon: "saffronSite/images/favicons/apple-touch-icon-152x152.png",
    maskIcon: "saffronSite/images/favicons/safari-pinned-tab.svg",
    msTileImage: "saffronSite/images/favicons/msapplication-icon-144x144.png",
  },
  themeColor: "#382F91",
};
